<template>
  <section
    class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 mt-12 gap-x-8 gap-y-6"
  >
    <section
      v-if="cat >= 1"
      class="
        flex
        align-middle
        items-center
        bg-white
        rounded
        shadow
        pl-6
        pr-4
        py-6
      "
    >
      <div>
        <img src="/img/profile pic (1).png" alt="cat" />
      </div>
      <div class="ml-6 w-3/6">
        <h1 class="text-base font-medium">Cat</h1>
        <div class="text-gray-400">
          {{ cat }}
          <span v-if="cat == 1">pet</span>
          <span v-if="cat > 1">pets</span>
        </div>
      </div>
    </section>
    <section
      v-if="dog >= 1"
      class="
        flex
        align-middle
        items-center
        bg-white
        rounded
        shadow
        pl-6
        pr-4
        py-6
      "
    >
      <div>
        <img src="/img/profile pic.png" alt="dog" />
      </div>
      <div class="ml-6 w-3/6">
        <h1 class="text-base font-medium">Dog</h1>
        <div class="text-gray-400">
          {{ dog }}
          <span v-if="dog == 1">pet</span>
          <span v-if="dog > 1">pets</span>
        </div>
      </div>
    </section>
    <section
      v-if="ferret >= 1"
      class="
        flex
        align-middle
        items-center
        bg-white
        rounded
        shadow
        pl-6
        pr-4
        py-6
      "
    >
      <div>
        <img
          src="/img/ferret.jpg"
          alt="Ferret"
          class="w-14 h-14 rounded-full"
        />
      </div>
      <div class="ml-6 w-3/6">
        <h1 class="text-base font-medium">Ferret</h1>
        <div class="text-gray-400">
          {{ ferret }}
          <span v-if="ferret == 1">pet</span>
          <span v-if="ferret > 1">pets</span>
        </div>
      </div>
    </section>
    <section
      v-if="pig >= 1"
      class="
        flex
        align-middle
        items-center
        bg-white
        rounded
        shadow
        pl-6
        pr-4
        py-6
      "
    >
      <div>
        <img src="/img/pig.jpg" alt="pig" class="w-14 h-14 rounded-full" />
      </div>
      <div class="ml-6 w-3/6">
        <h1 class="text-base font-medium">Pig</h1>
        <div class="text-gray-400">
          {{ pig }}
          <span v-if="pig == 1">pet</span>
          <span v-if="pig > 1">pets</span>
        </div>
      </div>
    </section>
    <section
      v-if="rat >= 1"
      class="
        flex
        align-middle
        items-center
        bg-white
        rounded
        shadow
        pl-6
        pr-4
        py-6
      "
    >
      <div>
        <img src="/img/rat.jpg" alt="rat" class="w-14 h-14 rounded-full" />
      </div>
      <div class="ml-6 w-3/6">
        <h1 class="text-base font-medium">Rat</h1>
        <div class="text-gray-400">
          {{ rat }}
          <span v-if="rat == 1">pet</span>
          <span v-if="rat > 1">pets</span>
        </div>
      </div>
    </section>
    <section
      v-if="mice >= 1"
      class="
        flex
        align-middle
        items-center
        bg-white
        rounded
        shadow
        pl-6
        pr-4
        py-6
      "
    >
      <div>
        <img src="/img/mice.jpg" alt="mice" class="w-14 h-14 rounded-full" />
      </div>
      <div class="ml-6 w-3/6">
        <h1 class="text-base font-medium">Mice</h1>
        <div class="text-gray-400">
          {{ mice }}
          <span v-if="mice == 1">pet</span>
          <span v-if="mice > 1">pets</span>
        </div>
      </div>
    </section>
    <section
      v-if="rabbit >= 1"
      class="
        flex
        align-middle
        items-center
        bg-white
        rounded
        shadow
        pl-6
        pr-4
        py-6
      "
    >
      <div>
        <img src="/img/profile pic (4).png" alt="rabbit" />
      </div>
      <div class="ml-6 w-3/6">
        <h1 class="text-base font-medium">Rabbit</h1>
        <div class="text-gray-400">
          {{ rabbit }}
          <span v-if="rabbit == 1">pet</span>
          <span v-if="rabbit > 1">pets</span>
        </div>
      </div>
    </section>
    <section
      v-if="bird >= 1"
      class="
        flex
        align-middle
        items-center
        bg-white
        rounded
        shadow
        pl-6
        pr-4
        py-6
      "
    >
      <div>
        <img src="/img/bird.jpg" alt="bird" class="w-14 h-14 rounded-full" />
      </div>
      <div class="ml-6 w-3/6">
        <h1 class="text-base font-medium">Bird</h1>
        <div class="text-gray-400">
          {{ bird }}
          <span v-if="bird == 1">pet</span>
          <span v-if="bird > 1">pets</span>
        </div>
      </div>
    </section>
    <section
      v-if="amphibians >= 1"
      class="
        flex
        align-middle
        items-center
        bg-white
        rounded
        shadow
        pl-6
        pr-4
        py-6
      "
    >
      <div>
        <pet-icon
          :width="'w-10'"
          :height="'h-10'"
          class="text-gray-300"
        ></pet-icon>
      </div>
      <div class="ml-6 w-3/6">
        <h1 class="text-base font-medium">Amphibians</h1>
        <div class="text-gray-400">
          {{ amphibians }}
          <span v-if="amphibians == 1">pet</span>
          <span v-if="amphibians > 1">pets</span>
        </div>
      </div>
    </section>
    <section
      v-if="fish >= 1"
      class="
        flex
        align-middle
        items-center
        bg-white
        rounded
        shadow
        pl-6
        pr-4
        py-6
      "
    >
      <div>
        <img src="/img/fish.jpg" alt="" class="w-14 h-14 rounded-full" />
      </div>
      <div class="ml-6 w-3/6">
        <h1 class="text-base font-medium">Fish</h1>
        <div class="text-gray-400">
          {{ fish }}
          <span v-if="fish == 1">pet</span>
          <span v-if="fish > 1">pets</span>
        </div>
      </div>
    </section>
    <section
      v-if="cattle >= 1"
      class="
        flex
        align-middle
        items-center
        bg-white
        rounded
        shadow
        pl-6
        pr-4
        py-6
      "
    >
      <div>
        <img
          src="/img/cattle.jpg"
          alt="cattle"
          class="w-14 h-14 rounded-full"
        />
      </div>
      <div class="ml-6 w-3/6">
        <h1 class="text-base font-medium">Cattle</h1>
        <div class="text-gray-400">
          {{ cattle }}
          <span v-if="cattle == 1">pet</span>
          <span v-if="cattle > 1">pets</span>
        </div>
      </div>
    </section>
    <section
      v-if="guinea_Pig >= 1"
      class="
        flex
        align-middle
        items-center
        bg-white
        rounded
        shadow
        pl-6
        pr-4
        py-6
      "
    >
      <div>
        <img src="/img/profile pic (2).png" alt="guinea pig" />
      </div>
      <div class="ml-6 w-3/6">
        <h1 class="text-base font-medium">Guinea Pig</h1>
        <div class="text-gray-400">
          {{ guinea_Pig }}
          <span v-if="guinea_Pig == 1">pet</span>
          <span v-if="guinea_Pig > 1">pets</span>
        </div>
      </div>
    </section>
    <section
      v-if="horse >= 1"
      class="
        flex
        align-middle
        items-center
        bg-white
        rounded
        shadow
        pl-6
        pr-4
        py-6
      "
    >
      <div>
        <img src="/img/profile pic (3).png" alt="horse" />
      </div>
      <div class="ml-6 w-3/6">
        <h1 class="text-base font-medium">Guinea Pig</h1>
        <div class="text-gray-400">
          {{ horse }}
          <span v-if="horse == 1">pet</span>
          <span v-if="horse > 1">pets</span>
        </div>
      </div>
    </section>
    <loading-model v-if="isLoading"></loading-model>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import PetIcon from '../../Icons/petIcon.vue'
import LoadingModel from '../../Utilities/loadingModel.vue'
export default {
  components: { PetIcon, LoadingModel },
  data() {
    return {
      pet_active: 'pets'
    }
  },

  computed: {
    ...mapState('pets', ['cat', 'isLoading']),
    ...mapState('pets', ['dog', 'isLoading']),
    ...mapState('pets', ['ferret', 'isLoading']),
    ...mapState('pets', ['pig', 'isLoading']),
    ...mapState('pets', ['rat', 'isLoading']),
    ...mapState('pets', ['mice', 'isLoading']),
    ...mapState('pets', ['rabbit', 'isLoading']),
    ...mapState('pets', ['bird', 'isLoading']),
    ...mapState('pets', ['amphibians', 'isLoading']),
    ...mapState('pets', ['fish', 'isLoading']),
    ...mapState('pets', ['cattle', 'isLoading']),
    ...mapState('pets', ['guinea_Pig', 'isLoading']),
    ...mapState('pets', ['horse', 'isLoading'])
  },

  mounted() {
    this.$store.dispatch('pets/petCat')
    this.$store.dispatch('pets/petDog')
    this.$store.dispatch('pets/petRats')
    this.$store.dispatch('pets/petRabbit')
    this.$store.dispatch('pets/petCattle')
    this.$store.dispatch('pets/petFish')
    this.$store.dispatch('pets/petMice')
    this.$store.dispatch('pets/petFerret')
    this.$store.dispatch('pets/petAmphibians')
    this.$store.dispatch('pets/petBird')
    this.$store.dispatch('pets/petPig')
    this.$store.dispatch('pets/petGuineaPig')
    this.$store.dispatch('pets/petHorse')
  }
}
</script>

<style></style>
