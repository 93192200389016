<template>
  <section>
    <dash-board-header></dash-board-header>
    <dash-board-nav
      v-on:active-button="activeBtn"
      @open-card-modal="$emit('open-modal')"
    ></dash-board-nav>
    <pet-card v-if="pets"></pet-card>
    <client-card v-if="client"></client-card>
    <farm-card v-if="farm"></farm-card>
    <!-- <appointment-card></appointment-card> -->
  </section>
</template>

<script>
import ClientCard from './cards/ClientCard.vue'
import DashBoardHeader from './DashBoardHeader.vue'
// import AppointmentCard from './AppointmentCard.vue'
import DashBoardNav from './DashBoardNav.vue'
import PetCard from './cards/PetCard.vue'
import FarmCard from './cards/FarmCard.vue'
export default {
  components: {
    /* AppointmentCard, */ PetCard,
    DashBoardNav,
    DashBoardHeader,
    ClientCard,
    FarmCard
  },
  data() {
    return {
      pets: false,
      farm: false,
      client: true
    }
  },
  methods: {
    activeBtn(val) {
      this.pets = val.pets
      this.farm = val.farm
      this.client = val.client
    }
  }
}
</script>

<style></style>
