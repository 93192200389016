<template>
  <main>
    <section class="mt-12">
      <section class="mb-14">
        <section
          class="
            flex
            sm:w-98
            w-full
            bg-white
            shadow-sm
            rounded
            px-4
            py-1
            items-center
            align-middle
          "
        >
          <input
            type="search"
            name=""
            id=""
            class="w-full py-1 outline-none text-lg"
            placeholder="Search Clients"
            v-model="client_search"
          />
          <search-icon
            class="ml-3"
            :width="'w-5'"
            :height="'h-5'"
          ></search-icon>
        </section>
      </section>
      <section
        class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-x-8 gap-y-6"
      >
        <div
          class="
            shadow
            bg-white
            flex flex-col
            place-items-center
            rounded
            px-2
            py-6
            relative
            card
          "
          v-for="(client, index) in new_clients"
          :key="index"
        >
          <div class="mt-6">
            <user-icon :width="'w-20'" :height="'h-20'"></user-icon>
          </div>
          <div class="text-center space-y-4 w-full">
            <h1 class="text-lg font-medium leading-10 capitalize">
              {{ client.client_details ? client.client_details.name : '' }}
            </h1>
            <small class="text-base text-gray-500 text-opacity-80">{{
              client.client_details ? client.client_details.bus_name : ''
            }}</small>
            <div class="border-t border-gray-200 pt-2 text-gray-600">
              <span class="mr-4"
                >Last update:
                {{ client.updatedAt ? getDate(client.updatedAt) : '' }}
              </span>
            </div>
          </div>
          <div
            class="absolute top-0 h-1/5 w-full card-top-bar"
            v-if="user.role >= 3"
          >
            <div class="flex justify-between px-4 py-3">
              <a
                href="javascript:;"
                class="cursor-not-allowed text-gray-200"
                @click="farmformModal(client.id)"
              >
                New Farm
              </a>
              <a
                href="javascript:;"
                class="hover:text-primary cursor-pointer text-gray-400"
                @click="petformModal(client.id)"
              >
                New Pet
              </a>
            </div>
          </div>
        </div>
      </section>
    </section>
    <pet-form
      :client_id="client_id"
      v-if="show_pet_form_modal"
      @close-card="closePetFormModal()"
    ></pet-form>
    <!-- <farm-form
      :client_id="client_id"
      v-if="show_farm_form_modal"
      @close-card="closefarmformModal()"
    ></farm-form> -->
    <loading-model v-if="dashboard_client_loader"></loading-model>
  </main>
</template>

<script>
import { mapState } from 'vuex'
// import FarmForm from '../Create/FarmForm.vue'
import PetForm from '../../Create/PetForm.vue'
import SearchIcon from '../../Icons/searchIcon.vue'
import UserIcon from '../../Icons/userIcon.vue'
import LoadingModel from '../../Utilities/loadingModel.vue'

export default {
  components: { SearchIcon, PetForm, /* FarmForm, */ UserIcon, LoadingModel },
  data() {
    return {
      months: [
        {
          full: 'January',
          short: 'Jan',
          days: 31
        },
        {
          full: 'Febuary',
          short: 'Feb',
          days: 29
        },
        {
          full: 'March',
          short: 'March',
          days: 31
        },
        {
          full: 'April',
          short: 'April',
          days: 30
        },
        {
          full: 'May',
          short: 'May',
          days: 31
        },
        {
          full: 'June',
          short: 'Jun',
          days: 30
        },
        {
          full: 'July',
          short: 'Jul',
          days: 31
        },
        {
          full: 'August',
          short: 'Aug',
          days: 31
        },
        {
          full: 'September',
          short: 'Sept',
          days: 30
        },
        {
          full: 'October',
          short: 'Oct',
          days: 31
        },
        {
          full: 'November',
          short: 'Nov',
          days: 30
        },
        {
          full: 'December',
          short: 'Dec',
          days: 31
        }
      ],
      client_id: null,
      new_clients: [],
      client_search: null,
      show_pet_form_modal: false,
      show_farm_form_modal: false,
      client_from_db: []
    }
  },
  created() {
    this.$store.dispatch('clients/getClientforDashboard')
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    ...mapState('clients', [
      'clients_list_for_dashboard',
      'dashboard_client_loader'
    ])
  },
  watch: {
    client_search(val) {
      this.new_clients = []
      this.searchClient(val)
    },

    clients_list_for_dashboard() {
      this.new_clients = this.clients_list_for_dashboard
    }
  },
  methods: {
    searchClient(val) {
      this.clients_list_for_dashboard.forEach(info => {
        var client_name = info.client_details.name.toLowerCase()
        var lowercase_val = val.toLowerCase()
        if (client_name.includes(lowercase_val)) {
          this.new_clients.push(info)
        }
      })
    },
    petformModal(val) {
      this.client_id = val
      this.show_pet_form_modal = !this.show_pet_form_modal
    },
    closePetFormModal() {
      this.client_id = null
      this.show_pet_form_modal = !this.show_pet_form_modal
    },
    farmformModal(val) {
      this.client_id = val
      this.show_farm_form_modal = !this.show_farm_form_modal
    },
    closefarmformModal() {
      this.client_id = null
      this.show_farm_form_modal = !this.show_farm_form_modal
    },
    getDate(val) {
      var month = new Date(val).getMonth()
      var day = new Date(val).getDate()
      var year = new Date(val).getFullYear()
      return this.months[month].short + ' ' + day + ', ' + year
    }
  },
  mounted() {
    if (this.clients_list_for_dashboard != undefined) {
      this.new_clients = this.clients_list_for_dashboard
    }
  }
}
</script>

<style scoped>
.card:hover {
  @apply bg-gray-50;
  @apply opacity-90;
  @apply transition;
  @apply duration-300;
  @apply ease-in-out;
}
</style>
