var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mt-12"},[_c('nav',{staticClass:"grid grid-flow-row md:grid-flow-col md:justify-between gap-y-4"},[_c('ul',{staticClass:"inline-flex"},[_c('li',{staticClass:"\n          py-3\n          w-24\n          lg:w-28\n          xl:w-40\n          shadow\n          text-center\n          mr-3\n          sm:mr-2\n          cursor-pointer\n          rounded-md\n          hover:bg-primary hover:text-white\n        ",class:{
          'text-white bg-primary': _vm.client,
          'text-black bg-gray-200': !_vm.client
        },on:{"click":function($event){return _vm.clientBtn()}}},[_vm._v(" Clients ")]),_c('li',{staticClass:"\n          py-3\n          w-24\n          lg:w-28\n          xl:w-40\n          shadow\n          text-center\n          mr-3\n          sm:mr-2\n          rounded-md\n          cursor-pointer\n          hover:bg-primary hover:text-white\n        ",class:{
          'text-white bg-primary': _vm.pets,
          'text-black bg-gray-200': !_vm.pets
        },on:{"click":function($event){return _vm.petBtn()}}},[_vm._v(" Pets ")]),_c('li',{staticClass:"\n          hidden\n          py-3\n          w-24\n          lg:w-28\n          xl:w-40\n          shadow\n          text-center\n          cursor-not-allowed\n          rounded-md\n          hover:bg-primary hover:text-gray-200\n        ",class:{
          'text-white bg-primary': _vm.farm,
          'text-black bg-gray-200': !_vm.farm
        },on:{"click":function($event){return _vm.farmBtn()}}},[_c('div',{staticClass:"flex justify-center align-middle items-center"},[_c('span',{},[_vm._v(" Farms ")]),_c('span',{staticClass:"ml-2"},[_c('lock-icon',{attrs:{"width":'w-6',"height":'h-6'}})],1)])])]),_c('ul',{},[_c('router-link',{staticClass:"\n          py-2\n          px-4\n          bg-primary\n          shadow-xl\n          rounded-lg\n          flex\n          justify-between\n          items-center\n          cursor-pointer\n        ",attrs:{"to":"/reports"}},[_c('span',{staticClass:"text-white items-center pl-3 sm:pl-6"},[_vm._v("Create New Report")]),_c('add-icon',{staticClass:"ml-28 sm:ml-36 md:ml-28 xl:ml-36",attrs:{"color":'text-white'}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }