<template>
  <section class="mt-12">
    <nav class="grid grid-flow-row md:grid-flow-col md:justify-between gap-y-4">
      <ul class="inline-flex">
        <li
          class="
            py-3
            w-24
            lg:w-28
            xl:w-40
            shadow
            text-center
            mr-3
            sm:mr-2
            cursor-pointer
            rounded-md
            hover:bg-primary hover:text-white
          "
          :class="{
            'text-white bg-primary': client,
            'text-black bg-gray-200': !client
          }"
          @click="clientBtn()"
        >
          Clients
        </li>
        <li
          class="
            py-3
            w-24
            lg:w-28
            xl:w-40
            shadow
            text-center
            mr-3
            sm:mr-2
            rounded-md
            cursor-pointer
            hover:bg-primary hover:text-white
          "
          :class="{
            'text-white bg-primary': pets,
            'text-black bg-gray-200': !pets
          }"
          @click="petBtn()"
        >
          Pets
        </li>
        <li
          class="
            hidden
            py-3
            w-24
            lg:w-28
            xl:w-40
            shadow
            text-center
            cursor-not-allowed
            rounded-md
            hover:bg-primary hover:text-gray-200
          "
          :class="{
            'text-white bg-primary': farm,
            'text-black bg-gray-200': !farm
          }"
          @click="farmBtn()"
        >
          <div class="flex justify-center align-middle items-center">
            <span class=""> Farms </span>
            <span class="ml-2"
              ><lock-icon :width="'w-6'" :height="'h-6'"></lock-icon
            ></span>
          </div>
        </li>
      </ul>
      <ul class="">
        <router-link
          class="
            py-2
            px-4
            bg-primary
            shadow-xl
            rounded-lg
            flex
            justify-between
            items-center
            cursor-pointer
          "
          to="/reports"
        >
          <span class="text-white items-center pl-3 sm:pl-6"
            >Create New Report</span
          >
          <add-icon
            :color="'text-white'"
            class="ml-28 sm:ml-36 md:ml-28 xl:ml-36"
          ></add-icon>
        </router-link>
      </ul>
    </nav>
  </section>
</template>

<script>
import addIcon from '../Icons/addIcon.vue'
import LockIcon from '../Icons/lockIcon.vue'
export default {
  components: { addIcon, LockIcon },
  data() {
    return {
      pets: false,
      farm: false,
      client: true
    }
  },
  methods: {
    petBtn() {
      this.neutral()
      this.pets = true
      this.emitToParent()
    },
    farmBtn() {
      this.neutral()
      this.farm = true
      this.emitToParent()
    },
    clientBtn() {
      this.neutral()
      this.client = true
      this.emitToParent()
    },
    neutral() {
      this.pets = false
      this.farm = false
      this.client = false
    },
    emitToParent() {
      var active = {
        pets: this.pets,
        farm: this.farm,
        client: this.client
      }
      this.$emit('active-button', active)
    }
  }
}
</script>

<style></style>
