<template>
  <section class="z-20 fixed inset-0 outline-none focus:outline-none bg_faint">
    <section
      class="absolute top-20 lg:top-8 bottom-8 left-0 right-0 overflow-y-scroll"
    >
      <section class="py-3 md:w-2/3 w-full mx-auto z-30">
        <section class="px-4 py-10 bg-white mx-3 shadow rounded sm:p-10">
          <div @click="$emit('close-card')" class="float-right cursor-pointer">
            <close-icon :color="'text-red-700'"></close-icon>
          </div>
          <section class="space-y-1 sm:mt-10 mt-10">
            <div
              class="w-full flex flex-col sm:flex-row align-middle items-center"
            >
              <h2 class="leading-relaxed text-xl text-left sm:w-1/3">
                Create Pet
              </h2>
            </div>
          </section>
          <section class="sm:w-5/6 sm:mx-auto">
            <section class="pt-6 pb-10 px-0 xl:px-14 space-y-4">
              <div
                class="
                  grid grid-cols-1
                  xl:grid-cols-3
                  gap-y-10
                  items-center
                  w-full
                "
              >
                <div class="col-span-1 place-self-center">
                  <div class="flex justify-center sm:mr-10 mt-6">
                    <pet-icon
                      :width="'w-40'"
                      :height="'w-40'"
                      class="text-gray-300"
                    ></pet-icon>
                  </div>
                </div>
                <div class="space-y-6 col-span-2 xl:ml-16">
                  <div class="space-y-6">
                    <div>
                      <input-pet-type
                        v-on:add-pet-type="petType"
                      ></input-pet-type>
                    </div>
                    <div>
                      <input-text
                        placeholder="pet name"
                        v-model="pet_details.pet_name"
                      ></input-text>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              class="pt-8 pb-10 px-0 xl:px-14 border-b border-t border-gray-200"
            >
              <h1 class="text-lg mb-5">Bio Data</h1>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-8">
                <div>
                  <input-text
                    placeholder="Breed"
                    required
                    v-model="pet_details.pet_breed"
                  ></input-text>
                </div>
                <div>
                  <input-text
                    placeholder="Colour"
                    required
                    v-model="pet_details.pet_colour"
                  ></input-text>
                </div>
                <div>
                  <input-gender
                    v-on:add-gender="petGender"
                    v-model="pet_details.pet_gender"
                  ></input-gender>
                </div>
                <div class="relative">
                  <input-text
                    placeholder="weight"
                    v-model="pet_details.pet_weight"
                    required
                  ></input-text>
                  <div class="absolute text-lg font-medium top-3 right-16">
                    lbs
                  </div>
                </div>
                <div>
                  <datetime
                    v-model="pet_details.pet_date_of_birth"
                    class="
                      border-b border-nadis-ash2
                      w-full
                      px-2
                      py-3
                      font-medium
                      bg-nadis-bg2
                      focus:outline-none
                    "
                    placeholder="DD MM YYYY"
                    required
                  ></datetime>
                </div>
                <div>
                  <input-altered
                    v-on:add-unit="petAltered"
                    v-model="pet_details.pet_altered"
                  ></input-altered>
                </div>
                <div>
                  <input-disabilities
                    v-on:add-unit="petDisabilities"
                  ></input-disabilities>
                </div>
                <div>
                  <input-text
                    v-if="pet_disabilities_status == 'Yes'"
                    placeholder="what kind of disabilities"
                    v-model="pet_disabilities_info"
                  ></input-text>
                </div>
              </div>
            </section>
            <section class="pt-8 pb-10 px-0 xl:px-14 space-y-6">
              <div>
                <h1 class="text-lg mb-4">Pet insurance</h1>
                <div class="flex">
                  <h1 class="text-lg">Does this pet have an insurance?</h1>
                  <div
                    v-for="(insurance, index) in insurance_checkbox"
                    :key="index"
                    class="mb-4 ml-3"
                  >
                    <input-checked
                      :checked="insurance.checked"
                      :unchecked="insurance.unchecked"
                      v-on:checked-value="petInsurance"
                    ></input-checked>
                  </div>
                </div>
              </div>
              <div class="space-y-6" v-if="pet_details.pet_insurance == true">
                <input-text
                  placeholder="Company's Name"
                  v-model="pet_details.insurance_name"
                ></input-text>
                <input-text
                  placeholder="Policy number"
                  v-model="pet_details.insurance_policy_number"
                ></input-text>
                <input-text
                  placeholder="Phone"
                  v-model="pet_details.insurance_phone"
                ></input-text>
              </div>
            </section>
            <section class="w-full flex justify-center mt-6">
              <button
                class="block py-3 px-16 text-white bg-primary rounded-md"
                @click="submitPetDetails"
              >
                save
              </button>
            </section>
          </section>
        </section>
      </section>
    </section>
  </section>
</template>

<script>
import { Datetime } from 'vue-datetime'
import uniqid from 'uniqid'
import InputGender from '../Utilities/InputGender.vue'
import InputText from '../Utilities/InputText.vue'
import InputChecked from '../Utilities/inputChecked.vue'
import InputAltered from '../Utilities/inputAltered.vue'
import InputDisabilities from '../Utilities/inputDisabilities.vue'
import InputPetType from '../Utilities/inputPetType.vue'
import CloseIcon from '../Icons/closeIcon.vue'
import Swal from 'sweetalert2'
import PetIcon from '../Icons/petIcon.vue'
export default {
  components: {
    InputText,
    InputGender,
    Datetime,
    InputChecked,
    InputAltered,
    InputDisabilities,
    InputPetType,
    CloseIcon,
    PetIcon
  },

  props: {
    client_id: String
  },

  data() {
    return {
      loading: false,
      pet_disabilities_info: null,
      pet_disabilities_status: null,
      pet_details: {
        pet_name: null,
        pet_type: null,
        pet_breed: null,
        pet_colour: null,
        pet_gender: null,
        pet_weight: null,
        pet_date_of_birth: null,
        pet_altered: null,
        pet_disabilities: null,
        pet_insurance: null,
        insurance_name: null,
        insurance_policy_number: null,
        insurance_phone: null
      },
      insurance_checkbox: [
        {
          checked: false,
          unchecked: true
        }
      ]
    }
  },

  computed: {
    user() {
      return this.$store.state.user
    }
  },

  watch: {
    pet_type(val) {
      if (val != undefined) {
        this.pet_details.pet_type = val
      } else if (val == undefined) {
        this.pet_details.pet_type = null
      }
    },

    pet_name(val) {
      if (val != '') {
        this.pet_details.pet_name = val
      } else if (val == '') {
        this.pet_details.pet_name = null
      }
    },

    pet_breed(val) {
      if (val != '') {
        this.pet_details.pet_breed = val
      } else if (val == '') {
        this.pet_details.pet_breed = null
      }
    },

    pet_colour(val) {
      if (val != '') {
        this.pet_details.pet_colour = val
      } else if (val == '') {
        this.pet_details.pet_colour = null
      }
    },

    pet_gender(val) {
      if (val != undefined) {
        this.pet_details.pet_gender = val
      } else if (val == undefined) {
        this.pet_details.pet_gender = null
      }
    },

    pet_weight(val) {
      if (val != '') {
        this.pet_details.pet_weight = val
      } else if (val == '') {
        this.pet_details.pet_weight = null
      }
    },

    pet_disabilities(val) {
      if (val != '') {
        this.pet_details.pet_disabilities = val
      } else if (val == '') {
        this.pet_details.pet_disabilities = null
      }
    },

    pet_insurance(val) {
      if (val != '') {
        this.pet_details.pet_insurance = val
      } else if (val == '') {
        this.pet_details.pet_insurance = null
      }
    },

    insurance_name(val) {
      if (val != '') {
        this.pet_details.insurance_name = val
      } else if (val == '') {
        this.pet_details.insurance_name = null
      }
    },

    insurance_policy_number(val) {
      if (val != '') {
        this.pet_details.insurance_policy_number = val
      } else if (val == '') {
        this.pet_details.insurance_policy_number = null
      }
    },

    insurance_phone(val) {
      if (val != '') {
        this.pet_details.insurance_phone = val
      } else if (val == '') {
        this.pet_details.insurance_phone = null
      }
    },

    pet_disabilities_status(val) {
      if (val == 'None') {
        this.pet_details.pet_disabilities = val
      }
    },
    pet_disabilities_info(val) {
      if (this.pet_disabilities_status == 'Yes') {
        this.pet_details.pet_disabilities = val
      }
    }
  },

  methods: {
    petType(val) {
      if (val != undefined) {
        this.pet_details.pet_type = val
      } else if (val == undefined) {
        this.pet_details.pet_type = null
      }
    },
    petGender(val) {
      if (val != undefined) {
        this.pet_details.pet_gender = val
      } else if (val != undefined) {
        this.pet_details.pet_gender = null
      }
    },
    petInsurance(val) {
      this.pet_details.pet_insurance = val.checked
    },
    petAltered(val) {
      if (val != undefined) {
        this.pet_details.pet_altered = val
      } else if (val == undefined) {
        this.pet_details.pet_altered = null
      }
    },
    petDisabilities(val) {
      this.pet_disabilities_status = val
    },
    submitPetDetails() {
      this.loading = true
      var uniqueId = uniqid()
      var pet = {
        id: uniqueId,
        pet_info: this.pet_details,
        hospital_id: this.user.hospital_id,
        client_id: this.client_id,
        isActive: false,
        createdAt: Date.now(),
        updatedAt: Date.now()
      }
      Swal.fire({
        title: 'Do you want to save?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `yes`
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('card/createPet', pet)

          let timerInterval
          Swal.fire({
            title: 'please wait!',
            html: 'creating pet <b></b>.',
            timer: 2000,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft()
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then(result => {
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'created successfully',
                showConfirmButton: false,
                timer: 1500
              })
              this.$emit('close-card')
            }
          })
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info')
        }
      })
      this.loading = false
    }
  },

  mounted() {}
}
</script>

<style scoped>
.bg_faint {
  background-color: rgba(39, 40, 41, 0.384);
}
</style>
