<template>
  <header>
    <section class="mb-10"></section>
    <section
      class="
        grid grid-cols-1
        md:grid-cols-3
        gap-x-8
        lg:gap-x-12
        gap-y-6
        lg:gap-y-7
      "
    >
      <div
        class="
          bg-light_blue
          w-full
          pt-11
          pb-14
          px-8
          md:px-5
          lg:px-8
          h-full
          space-y-2
          rounded
          shadow
        "
      >
        <h3 class="text-white text-3xl">
          {{ client_count }} <span v-if="client_count > 1">clients</span>
          <span>client</span>
        </h3>
        <h1 class="text-white text-3xl opacity-60">
          {{ pets.length }} <span v-if="pets.length > 1">pets</span>
          <span>pet</span>
        </h1>
      </div>
      <!-- <div
        class="
          bg-primary
          w-full
          pt-11
          pb-14
          px-8
          md:px-5
          lg:px-8
          h-full
          space-y-2
          rounded
          shadow
        "
      >
        <p class="text-white">Upcoming</p>
        <h3 class="text-white text-3xl">6 appointments</h3>
      </div> -->
      <!-- <div
        class="
          w-full
          bg-white
          shadow
          pt-11
          pl-8
          pr-5
          pb-8
          md:pb-0 md:px-5
          lg:px-8
        "
      >
        <p class="font-medium text-lg opacity-60">Next appointment</p>
        <h3 class="text-primary text-xl">Mr Okeke brown alsesian</h3>
        <div class="flex text-black leading-7">
          <small class="font-semibold">Tomorrow</small>
          <small class="font-semibold ml-6">12:30 pm</small>
        </div>
        <router-link
          class="
            float-right
            md:float-none
            xl:float-right
            px-2
            py-1
            bg-gray-100
            font-medium
            flex
            rounded-sm
          "
          to="/"
        >
          <p>View appointments</p>
          <arrowrighticon class="md:ml-1 lg:ml-7 ml-7"></arrowrighticon>
        </router-link>
      </div> -->
    </section>
  </header>
</template>

<script>
import { mapState } from 'vuex'
// import Arrowrighticon from '../Icons/arrowrighticon.vue'
export default {
  components: {
    /*Arrowrighticon */
  },
  created() {
    this.$store.dispatch('clients/clientCount')
    this.$store.dispatch('pets/getPets')
  },
  computed: {
    ...mapState('clients', ['client_count']),
    ...mapState('pets', ['pets'])
  }
}
</script>

<style></style>
