<template>
  <section class="mt-10 sm:mt-16 sm:w-2/4 mx-auto">
    <under-construction-svg></under-construction-svg>
  </section>
</template>

<script>
import UnderConstructionSvg from '../../Icons/underConstructionSvg.vue'
export default {
  components: {
    UnderConstructionSvg
  },
  data() {
    return {}
  }
}
</script>

<style></style>
